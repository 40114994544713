<template>
  <main id="Contact">
    <div class="row">
      <header>
        <p class="sub_title"><b>CONTACT</b></p>
        <h3 class="title title_mark"><b>聯絡我們</b></h3>
      </header>
      <div class="content_box">
        <p class="content">
          有任何關於產品或銷售相關問題歡迎透過<a>客服電話</a>或是<a>粉絲專頁</a>與我們聯絡，
          您也可以填寫以下表單，我們將會指派專人與您聯絡。
        </p>

        <div class="form_group">
          <div class="input_group">
            <div class="title_group">
              <span class="require">必須</span>
              <p>姓名</p>
            </div>
            <input
              placeholder="請輸入您的姓名"
              type="text"
              name="name"
              v-model="form_data.name"
            />
          </div>
          <p class="error">{{ GetError('name') }}</p>
        </div>
        <div class="form_group">
          <div class="input_group">
            <div class="title_group">
              <span class="require">必須</span>
              <p>電話</p>
            </div>
            <input
              placeholder="請輸入您的電話號碼"
              type="text"
              name="phone"
              v-model="form_data.phone"
            />
          </div>
          <p class="error">{{ GetError('phone') }}</p>
        </div>
        <div class="form_group">
          <div class="input_group">
            <div class="title_group">
              <span class="require">必須</span>
              <p>電子信箱</p>
            </div>
            <input
              placeholder="請輸入您的電子信箱"
              type="text"
              name="email"
              v-model="form_data.email"
            />
          </div>
          <p class="error">{{ GetError('email') }}</p>
        </div>
        <div class="form_group">
          <div class="input_group">
            <div class="title_group">
              <span class="require">必須</span>
              <p>訊息內容</p>
            </div>
            <textarea
              placeholder="請輸入您的訊息內文"
              name="comment"
              v-model="form_data.comment"
            ></textarea>
          </div>
          <p class="error">{{ GetError('comment') }}</p>
        </div>

        <div class="form_footer">
          <button @click="Validite" class="submit_btn link_mark">
            <div><b>確認送出</b></div>
          </button>
        </div>
      </div>
    </div>
    <MainFooter />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import MainFooter from '@/components/MainFooter';
import { CreateContact } from '@/api/contact.js';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'Contact',
  mixins: [SmoothScrollResister],
  components: { MainFooter },
  data() {
    return {
      form_data: {
        name: '',
        phone: '',
        email: '',
        comment: '',
      },
      errors: [],
      send_state: 'wait',
      meta_data: null,
    };
  },
  methods: {
    Validite() {
      this.errors = [];
      let phone_re = /^[0-9]+$/;
      let email_re = /[\w-]+@([\w-]+\.)+[\w-]+/;
      if (this.form_data.name == '' || this.form_data.length <= 0) {
        this.errors.push({
          type: 'name',
          msg: '請輸入您的姓名',
        });
      }
      if (this.form_data.phone == '' || this.form_data.phone <= 0) {
        this.errors.push({
          type: 'phone',
          msg: '請輸入您的聯絡電話',
        });
      } else if (!phone_re.test(this.form_data.phone)) {
        this.errors.push({
          type: 'phone',
          msg: '請輸入正確的電話號碼',
        });
      }

      if (this.form_data.email == '' || this.form_data.email <= 0) {
        this.errors.push({
          type: 'email',
          msg: '請輸入您的電子信箱',
        });
      } else if (!email_re.test(this.form_data.email)) {
        this.errors.push({
          type: 'email',
          msg: '請輸入正確的電子信箱',
        });
      }
      if (this.form_data.comment == '' || this.form_data.comment <= 0) {
        this.errors.push({
          type: 'comment',
          msg: '請輸入您的訊息',
        });
      }

      if (this.errors.length <= 0) {
        this.SendMessage();
      } else {
        this.ScrollToEl(0);
      }
    },
    SendMessage() {
      if (this.send_state == 'wait') {
        this.$store.commit('SetPageLoading', 1);
        this.GA_Contact();
        CreateContact(this.form_data).then((response) => {
          if (response.status == 'success') {
            this.$store.commit('SetDialog', {
              action: true,
              content: '訊息已送出',
            });
          } else {
            this.$store.commit('SetDialog', {
              action: true,
              content: '發生錯誤請稍後再試',
            });
          }
          this.$store.commit('SetPageLoading', -1);
        });
      }
    },
    GetError(key) {
      let error = this.errors.filter((item) => item.type == key);
      return error.length <= 0 ? '' : error[0].msg;
    },
  },
  mounted() {
    this.RegisterGsap('Contact');
    this.$emit('init-cursor');
    window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData('聯絡我們', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
