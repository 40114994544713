<template>
  <div v-if="dialog" id="MainImageModal">
    <div class="modal_box">
      <a @click="Close" class="close_btn">
        <i></i>
        <i></i>
      </a>
      <img
        :style="`max-height:${max_height}px`"
        id="ModalImage"
        :src="images[active_index]"
      />
      <div class="footer">
        <a @click="Close" class="close_btn">
          <i></i>
          <i></i>
        </a>
        <div class="control_box">
          <a @click="ChangeImage(-1)" class="left_arrow_btn"><i></i><i></i></a>
          <a @click="ChangeImage(1)" class="right_arrow_btn"><i></i><i></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import main_image_modal_gsap from "@/common/GSAP/main_image_modal.js"
export default {
  name: "MainImageModal",
  mixins: [SmoothScrollResister, main_image_modal_gsap],
  data() {
    return {
      active_index: 0,
    }
  },
  methods: {
    ChangeImage(action) {
      if (action == 1) {
        this.active_index == this.images.length - 1 ? this.active_index = 0 : this.active_index += 1
      }
      else {
        this.active_index == 0 ? this.active_index = this.images.length - 1 : this.active_index -= 1
      }
    },
    Close() {
      this.$store.commit("SetImageModal", {
        status: false,
        images: [],
        active_index: 0
      })
    }
  },
  computed: {
    dialog() {
      return this.$store.state.image_modal.status
    },
    images() {
      return this.$store.state.image_modal.images
    },
    max_height() {
      return window.innerHeight - 100
    }
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.active_index = this.$store.state.image_modal.active_index
        this.ModalToggle(true)
        this.OpenMenu()
      } else {
        this.ModalToggle(false)
        this.CloseMenu()
      }
    }
  }
}
</script>