import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            product_cover_gsap: null,
        }
    },
    methods: {
        InitBox() {
            let cards = document.querySelectorAll("#ProductCover .box")
            cards.forEach((card) => {
                let card_animation = gsap.timeline()
                card_animation.fromTo(
                    card, {
                        opacity: 0,
                        webkitFilter: "blur(40px)",
                    }, {
                        opacity: 1,
                        webkitFilter: "blur(0px)",
                        ease: "none",
                        duration: .6,
                        delay: 1
                    }
                )
            })
        },
        SetUp() {
            this.RegisterGsap("ProductPage")
            this.InitBox()
            gsap.fromTo("#ProductCover .img_box img", {
                opacity: 0,
                x: "-50px",
            }, {
                ease: "power2.out",
                duration: 1,
                delay: .4,
                x: "0",
                opacity: 1
            })
            let vm = this
            vm.product_cover_gsap = gsap.timeline({
                scrollTrigger: {
                    trigger: "#ProductCover",
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                    // markers: true,
                    toggleActions: "restart pause reverse pause"
                }
            })

            vm.product_cover_gsap.fromTo("#ProductCover .img_box img", {
                y: 0
            }, {
                y: "100px"
            })


            this.InitGsapMarker()
        }
    },
    mounted() {

    },
    beforeDestroy() {
        this.product_cover_gsap != null ? this.product_cover_gsap.kill() : ""
    }
}