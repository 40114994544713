import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_about_gsap: null
        }
    },
    mounted() {
        this.RegisterGsap("Home")
        let vm = this
        ScrollTrigger.matchMedia({
            "(min-width:600px)"() {
                vm.home_about_gsap = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#HomeAbout",
                        start: "top center",
                        end: "center center",
                        scrub: true,
                        // markers: true,
                        toggleActions: "restart pause reverse pause"
                    }
                })

                vm.home_about_gsap.fromTo("#HomeAbout .back_text", {
                    opacity: 0,
                    scale: 10,
                }, {
                    ease: "power2.out",
                    scale: 1,
                    opacity: .1
                })
            },
            "(max-width:600px)"() {
                vm.home_about_gsap = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#HomeAbout",
                        start: "top center",
                        end: "center center",
                        scrub: true,
                        // markers: true,
                        toggleActions: "restart pause reverse pause"
                    }
                })

                vm.home_about_gsap.fromTo("#HomeAbout .back_text", {
                    opacity: 0,
                    scale: 10,
                }, {
                    ease: "power2.out",
                    scale: 1,
                    opacity: .1
                })
            },
        })
        this.InitGsapMarker()
    },
    beforeDestroy() {
        this.home_about_gsap != null ? this.home_about_gsap.kill() : ""
    }
}