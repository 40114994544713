export default {
    methods: {

        //點擊首頁大圖
        GA_ClickCarousel(title) {
            let data = {
                event: 'click_carousel',
                category: '點擊首頁輪播-' + title, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '點擊首頁輪播-' + title, // 標籤 字元(選填)
                action_name: '點擊首頁輪播-' + title,
            }
            this.$gtm.trackEvent(data);
        },
        //點擊首頁熱門商品
        GA_ClickHotProduct(title) {
            let data = {
                event: 'click_hot_product',
                category: '點擊熱門商品-' + title.replace(/(<([^>]+)>)/gi, ""), // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '點擊熱門商品-' + title.replace(/(<([^>]+)>)/gi, ""), // 標籤 字元(選填)
                action_name: '點擊熱門商品-' + title.replace(/(<([^>]+)>)/gi, ""),
            }
            this.$gtm.trackEvent(data);
        },
        //點擊首頁下方連結
        GA_ClickHomeLink(title) {
            let data = {
                event: 'click_home_link',
                category: '點擊首頁-' + title, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '點擊首頁-' + title, // 標籤 字元(選填)
                action_name: '點擊首頁-' + title,
            }
            this.$gtm.trackEvent(data);
        },

        //選擇經銷店家
        GA_ClickShop(title) {
            let data = {
                event: 'click_shop',
                category: '點擊店家-' + title, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '點擊店家-' + title, // 標籤 字元(選填)
                action_name: '點擊店家-' + title,
            }
            this.$gtm.trackEvent(data);
        },

        //查看商品
        GA_ViewProduct(product) {
            let data = {
                event: 'view_product',
                category: '選擇商品-' + product.name, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '選擇商品-' + product.name, // 標籤 字元(選填)
                action_name: '選擇商品-' + product.name,
            }
            this.$gtm.trackEvent(data);
        },

        //選擇客製化商品
        GA_ChooseCustomizeProduct(product) {
            let data = {
                event: 'choose_customize_product',
                category: '選擇客製化商品-' + product.name, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '選擇客製化商品-' + product.name, // 標籤 字元(選填)
                action_name: '選擇客製化商品-' + product.name,
            }
            this.$gtm.trackEvent(data);
        },

        //產生訂單
        GA_CreateOrder(product) {
            let data = {
                event: 'create_customize_order',
                category: '建立客製化訂單-' + product.name, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '建立客製化訂單-' + product.name, // 標籤 字元(選填)
                action_name: '建立客製化訂單-' + product.name,
            }
            this.$gtm.trackEvent(data);
        },

        //點擊影片
        GA_ViewVideo(video) {
            let data = {
                event: 'view_video',
                category: '點擊影片-' + video, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '點擊影片-' + video, // 標籤 字元(選填)
                action_name: '點擊影片-' + video,
            }
            this.$gtm.trackEvent(data);
        },

        //下載檔案
        GA_DownLoadFile(file) {
            let data = {
                event: 'download_file',
                category: '下載檔案-' + file, // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '下載檔案-' + file, // 標籤 字元(選填)
                action_name: '下載檔案-' + file,
            }
            this.$gtm.trackEvent(data);
        },

        //聯絡我們
        GA_Contact() {
            let data = {
                event: 'contact',
                category: '聯絡我們', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: '聯絡我們', // 標籤 字元(選填)
                action_name: '聯絡我們'
            }
            this.$gtm.trackEvent(data);
        },
    }
}