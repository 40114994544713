import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});

export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            active_news: 0,
            total_news: 6,
            home_news_gsap: null
        }
    },
    methods: {
        SetNewsData(active_news, total_news) {
            this.total_news = total_news
            this.active_news = active_news
        },
        Reset() {
            this.active_news = 0
        },
        GetCardWidth() {
            if (window.innerWidth <= 600) {
                return window.innerWidth + 20
            } else if (window.innerWidth <= 768) {
                return window.innerWidth * 0.8 + 60
            } else {
                return 410
            }
        },
        ScrollNews(val) {
            if (val == 1) {
                //next
                this.active_news == this.total_news - 1 ? this.active_news = 0 : this.active_news += 1
            } else {
                //prev
                this.active_news == 0 ? "" : this.active_news -= 1
            }

            gsap.to("#HomeNews .list", {
                x: this.active_news * this.GetCardWidth() * -1 + "px",
                ease: "power2.out"
            })
        },
        Init() {
            this.RegisterGsap("Home")
            let vm = this
            ScrollTrigger.matchMedia({
                "(min-width:600px)"() {
                    vm.home_news_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#HomeNews",
                            start: "top center",
                            end: "center center",
                            // markers: true,
                            toggleActions: "restart play complete reverse",
                        }
                    })

                    vm.home_news_gsap.add(function () {
                        vm.Reset()
                    })

                    vm.home_news_gsap.fromTo("#HomeNews .bg_text_box", {
                        opacity: 0,
                        y: "50px"
                    }, {
                        ease: "power2.out",
                        opacity: .1,
                        y: "0px",
                        duration: 1,
                    }, 0)

                    vm.home_news_gsap.fromTo("#HomeNews .list", {
                        opacity: 0,
                        x: "300",
                    }, {
                        ease: "power2.out",
                        opacity: 1,
                        duration: 1,
                        x: "0"
                    }, 1)

                    vm.home_news_gsap.to("#HomeNews .title b", {
                        y: 0,
                        delay: .4,
                        duration: .3
                    }, 0)

                    vm.home_news_gsap.fromTo("#HomeNews .more_btn", {
                        x: "-30px",
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        delay: .4,
                        duration: .3
                    }, 0)

                    vm.home_news_gsap.from("#HomeNews .control_box", {
                        y: "50px",
                        opacity: 0,
                        delay: .4,
                        duration: 1
                    }, 1)

                },
                "(max-width:600px)"() {
                    vm.home_news_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#HomeNews",
                            start: "top center",
                            end: "center center",
                            // markers: true,
                            toggleActions: "restart play complete reverse",
                        }
                    })

                    vm.home_news_gsap.add(function () {
                        vm.Reset()
                    })

                    vm.home_news_gsap.fromTo("#HomeNews .bg_text_box", {
                        opacity: 0,
                        y: "50px"
                    }, {
                        ease: "power2.out",
                        opacity: .1,
                        y: "0px",
                        duration: 1,
                    }, 0)

                    vm.home_news_gsap.fromTo("#HomeNews .list", {
                        opacity: 0,
                        x: "300",
                    }, {
                        ease: "power2.out",
                        opacity: 1,
                        duration: 1,
                        x: "0"
                    }, 1)

                    vm.home_news_gsap.to("#HomeNews .title b", {
                        y: 0,
                        delay: .4,
                        duration: .3
                    }, 0)

                    vm.home_news_gsap.fromTo("#HomeNews .more_btn", {
                        x: "-30px",
                        opacity: 0,
                    }, {
                        x: 0,
                        opacity: 1,
                        delay: .4,
                        duration: .3
                    }, 0)

                    vm.home_news_gsap.from("#HomeNews .control_box", {
                        y: "50px",
                        opacity: 0,
                        delay: .4,
                        duration: 1
                    }, 0)
                },
            })
            this.InitGsapMarker()
        }
    },
    mounted() {

    },
    beforeDestroy() {
        this.home_news_gsap != null ? this.home_news_gsap.kill() : ""
    }
}