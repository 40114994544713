<template>
  <section id="AboutInfo">
    <div class="row">
      <header>
        <p class="sub_title"><b>COMPANY PROFILE</b></p>
        <h3 class="title"><b>公司資訊</b></h3>
      </header>

      <div class="info_box">
        <ol v-if="company_info_data != null">
          <li>
            <h4>公司名稱</h4>
            <p>{{ company_info_data.name }}</p>
          </li>
          <li>
            <h4>建立日期</h4>
            <p>{{ company_info_data.create_date }}</p>
          </li>
          <li>
            <h4>公司地址</h4>
            <p>{{ company_info_data.address }}</p>
          </li>
          <li>
            <h4>公司電話</h4>
            <p>{{ company_info_data.phone }}</p>
          </li>
          <li>
            <h4>公司傳真</h4>
            <p>{{ company_info_data.fax }}</p>
          </li>
          <li>
            <h4>統一編號</h4>
            <p>{{ company_info_data.tax_id }}</p>
          </li>
        </ol>
      </div>
    </div>
    <div class="map">
      <!-- <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.0081026972093!2d120.20319441580575!3d23.060164520706856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e777f5493077d%3A0x1bac7e37104795f5!2zNzA55Y-w5Y2X5biC5a6J5Y2X5Y2A5bel5piO5Y2X5LiA6LevMTA36Jmf!5e0!3m2!1szh-TW!2stw!4v1632821980247!5m2!1szh-TW!2stw"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe> -->
      <a
        href="https://www.google.com/maps/search/?api=1&query=709台南市安南區工明南一路107號"
        class="more_btn"
      >
        <div>
          <b>點我導航</b>
        </div>
      </a>
      <div class="google-map" id="map"></div>
    </div>
  </section>
</template>

<script>
import about_info_gsap from '@/common/GSAP/About/about_info.js';
export default {
  name: 'AboutInfo',
  mixins: [about_info_gsap],
  props: {
    company_info: {
      require: true,
    },
  },
  data() {
    return {
      map: null,
      // 預設經緯度在信義區附近
      lat: 23.0601445,
      lng: 120.2053126,
    };
  },
  methods: {
    initMap() {
      // 透過 Map 物件建構子建立新地圖 map 物件實例，並將地圖呈現在 id 為 map 的元素中
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        mapId: 'efc0a2f7b8acc262',
        // 設定地圖的中心點經緯度位置
        center: { lat: this.lat, lng: this.lng },
        // 設定地圖縮放比例 0-20
        zoom: 19,
        // 限制使用者能縮放地圖的最大比例
        maxZoom: 19,
        // 限制使用者能縮放地圖的最小比例
        minZoom: 3,
        // 設定是否呈現右下角街景小人
        streetViewControl: false,
        // 設定是否讓使用者可以切換地圖樣式：一般、衛星圖等
        mapTypeControl: false,
        zoomControl: false,
      });
    },
    // 建立地標
    setMarker() {
      // 建立一個新地標
      // eslint-disable-next-line no-unused-vars
      const marker = new window.google.maps.Marker({
        // 設定地標的座標
        position: { lat: this.lat, lng: this.lng },
        // 設定地標要放在哪一個地圖
        map: this.map,
        icon: '/img/map_icon.png',
      });
    },
  },
  computed: {
    company_info_data() {
      if (this.company_info.length > 0) {
        return {
          name: this.company_info.filter(
            (item) => item.column_name == 'company_name'
          )[0].title,
          create_date: this.company_info.filter(
            (item) => item.column_name == 'company_create_date'
          )[0].title,
          address: this.company_info.filter(
            (item) => item.column_name == 'company_address'
          )[0].title,
          phone: this.company_info.filter(
            (item) => item.column_name == 'company_phone'
          )[0].title,
          fax: this.company_info.filter(
            (item) => item.column_name == 'company_fax'
          )[0].title,
          tax_id: this.company_info.filter(
            (item) => item.column_name == 'company_tax_id'
          )[0].title,
        };
      } else {
        return null;
      }
    },
  },
  watch: {
    company_info() {
      if (this.company_info.length > 0) {
        this.$nextTick(() => {
          this.InitGSAP();
        });
      }
    },
  },
  mounted() {
    this.initMap();
    this.setMarker();
  },
};
</script>
