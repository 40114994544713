import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import SmoothScrollResister from '../common/SmoothScrollResister'
export default new Vuex.Store({
  state: {
    image_modal: {
      status: false,
      images: [],
      active_index: 0,
    },
    dialog: {
      state: false,
      content: ""
    },
    loading: 0
  },
  mutations: {
    SetImageModal(state, {
      status,
      images,
      active_index
    }) {
      state.image_modal = {
        status: status,
        images: images,
        active_index: active_index
      }
    },
    SetPageLoading(state, action) {
      state.loading += action
    },
    SetDialog(state, {
      action,
      content
    }) {
      state.dialog.state = action
      state.dialog.content = content
    }
  },
  actions: {},
  modules: {},
  mixins: {
    SmoothScrollResister
  }
})