import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            news_list_gsap: null,
        }
    },
    methods: {
        InitCard() {
            let cards = document.querySelectorAll("#NewsList .card_list")
            gsap.fromTo(
                cards, {
                    opacity: 0,
                    y: "20px"
                }, {
                    opacity: 1,
                    y: 0,
                    ease: "none",
                    duration: .6
                }
            )
        }
    },
    mounted() {
        this.RegisterGsap("NewsList")
        this.InitCard(-1)
        this.InitGsapMarker()
    },
    beforeDestroy() {
        this.news_list_gsap != null ? this.news_list_gsap.kill() : ""
    }
}