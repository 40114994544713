<template>
  <section id="HomeFactory">
    <div class="row">
      <div class="content_box">
        <header>
          <p class="sub_title">
            <b v-html="page_data[0].sub_title"></b>
          </p>
          <h3 class="title title_mark">
            <b v-html="page_data[0].title"></b>
          </h3>
        </header>
        <div class="content" v-html="page_data[0].content"></div>
      </div>

      <picture class="img_box">
        <img @load="onImgLoad(1)" :src="page_data[0].image" />
      </picture>
    </div>
    <div class="bg"></div>
  </section>
</template>

<script>
import GSAP from "@/common/GSAP/Home/home_factory.js"
export default {
  name: "HomeFactory",
  mixins: [GSAP],
  props: {
    page_data: {
      require: true
    }
  },
  data() {
    return {
      image_loaded: 0
    }
  },
  mounted() {
    this.Init()
  },
  methods: {
    onImgLoad() {
      // console.log(`factory is loaded`)
      this.image_loaded += 1
      if (this.image_loaded == 1) {
        this.$emit("image-loaded")
      }
    }
  }
}
</script>