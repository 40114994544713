<template>
  <main id="NewsList">
    <div class="row">
      <header>
        <p class="sub_title"><b>NEWS</b></p>
        <h3 class="title title_mark"><b>最新消息</b></h3>
      </header>
      <div class="option_box">
        <router-link
          v-for="item in category"
          :key="item.id"
          :to="`/news/list/${item.value}`"
          class="link_mark"
          :class="{ active: item.value == $route.params.type }"
        >
          {{ item.text }}
        </router-link>

        <div class="select_box">
          <span>新聞分類:</span>
          <select v-model="active_category">
            <option v-for="item in category" :value="item.value" :key="item.id">
              {{ item.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="card_list">
        <router-link
          :to="`/news/page/${item.news_id}`"
          class="card link_mark"
          v-for="item in filter_data"
          :key="item.id"
        >
          <div class="img_box">
            <img v-if="item.cover == ''" src="/img/index.jpg" />
            <img v-else :src="item.cover" />
          </div>
          <div class="content">
            <div class="header">
              <span>{{ item.category }}</span>
              <time>{{ item.create_time.slice(0, 10) }}</time>
            </div>
            <h3>{{ item.title }}</h3>
          </div>
        </router-link>
      </div>
      <div class="footer">
        <button
          v-if="active_page * 9 < original_data.length"
          @click="LoadMore"
          class="more_btn link_mark"
        >
          <div><b>讀取更多</b></div>
        </button>
      </div>
    </div>
    <MainFooter />
  </main>
</template>

<script>
import NewsListGSAP from '@/common/GSAP/News/news_list.js';
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import MainFooter from '@/components/MainFooter';
import { getNewsList } from '@/api/news.js';
import { GetMetaData } from '@/common/meta';
import { ReturnErrorPage } from '@/common/prerender_event.js';
export default {
  name: 'NewsList',
  mixins: [SmoothScrollResister, NewsListGSAP],
  components: {
    MainFooter,
  },
  data() {
    return {
      category: [
        {
          text: '全部',
          value: 'all',
        },
        {
          text: '重要公告',
          value: 'important',
        },
        {
          text: '最新消息',
          value: 'news',
        },
        {
          text: '活動消息',
          value: 'event',
        },
      ],
      news_data: [],
      original_data: [],
      active_page: 1,
      active_category: 'all',
      news_type: 'all',
      meta_data: null,
    };
  },
  methods: {
    async GetNewsData() {
      getNewsList().then((res) => {
        let data = res.data;
        this.original_data = data;
        this.original_data.sort((a, b) => {
          return a.position - b.position;
        });
        this.news_data = this.original_data.splice(0, this.active_page * 9);
        if (this.news_data.length <= 0) {
          ReturnErrorPage();
        } else {
          this.$nextTick(() => {
            this.news_type = this.$route.params.category;
            this.RegisterGsap('NewsList');
            this.InitCard((this.active_page - 1) * 9 - 1);
            this.$emit('init-cursor');
            window.prerenderReady = true;
          });
        }
      });
    },
    LoadMore() {
      if ((this.active_page + 1) * 9 <= this.original_data.length) {
        this.active_page += 1;
        this.news_data = this.original_data.splice(0, this.active_page * 9);
        this.$nextTick(() => {
          this.InitCard((this.active_page - 1) * 9 - 1);
          this.$emit('init-cursor');
        });
      }
    },
  },
  created() {
    let category_exist = false;
    this.category.forEach((item) => {
      item.value == this.$route.params.type ? (category_exist = true) : '';
    });
    !category_exist
      ? ReturnErrorPage()
      : (this.meta_data = GetMetaData(
          '最新消息',
          '歡迎使用MJP 茂建科技官方網站，最新的公告資訊、優惠活動、新品預告都在MJP 茂建科技官方網站。',
          ''
        ));
    this.GetNewsData();
  },
  computed: {
    filter_data() {
      if (this.active_category != 'all') {
        let category_title = this.category.filter(
          (item) => item.value == this.active_category
        )[0].text;
        return this.news_data.filter((item) => item.category == category_title);
      } else {
        return this.news_data;
      }
    },
  },
  watch: {
    $route() {
      this.active_category = this.$route.params.type;
      this.InitCard(-1);
    },
    active_category(val) {
      this.$router.push('/news/list/' + val);
    },
  },
  filters: {
    number(val) {
      return val <= 9 ? '0' + val : val;
    },
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
