<template>
  <section id="HomeHotProduct">
    <div class="row">
      <div class="header">
        <header>
          <p class="sub_title"><b>HOT PRODUCTS</b></p>
          <h3 class="title title_mark"><b>熱門商品</b></h3>
        </header>
        <div class="content">
          <a class="more_btn link_mark">LEARN MORE<span></span></a>
          <div class="control_box">
            <a @click="ScrollProduct(-1)" class="left_arrow_btn"
              ><i></i><i></i
            ></a>
            <a @click="ScrollProduct(1)" class="right_arrow_btn"
              ><i></i><i></i
            ></a>
          </div>
        </div>
      </div>
      <div class="list">
        <div
          v-for="(item, item_index) in active_data"
          class="card"
          :key="item.id"
        >
          <div class="content">
            <h4 v-html="item.title"></h4>
            <span>NT$ {{ item.price }} 元</span>
            <a :href="item.url" class="link_mark">VIEW MORE</a>
          </div>

          <picture>
            <img
              @load="onImgLoad(item_index)"
              :src="item.image"
              :alt="item.name"
            />
          </picture>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 313.81 386.76">
            <g>
              <path
                class="cls-1"
                d="M.85,0c0,.62.58.67.94.89q77,47.47,154.05,95a1.73,1.73,0,0,0,2.15,0Q235,48.38,311.92,1c.39-.24,1-.27,1-1h.85q0,62.18,0,124.36,0,82.14,0,164.29a2.71,2.71,0,0,1-1.52,2.67Q235.11,338.81,158,386.39a1.74,1.74,0,0,1-2.15,0Q78.59,338.75,1.3,291.18A2.41,2.41,0,0,1,0,288.79Q.07,145.1,0,1.42C0,.94,0,.47,0,0ZM311.14,146.85q0-70.35,0-140.72c0-.41.23-1-.15-1.21s-.76.25-1.1.45L294.17,15Q226.07,57,158,99a1.74,1.74,0,0,1-2.16,0Q80,52.17,4.05,5.44c-.37-.23-.73-.82-1.21-.5s-.13.8-.13,1.22q0,140.66,0,281.3a2.17,2.17,0,0,0,1.15,2.16q76,46.8,152,93.66a1.73,1.73,0,0,0,2.14,0q75.93-46.81,151.9-93.58a2.29,2.29,0,0,0,1.27-2.25Q311.11,217.14,311.14,146.85Z"
              />
              <path
                class="cls-2"
                d="M302.88,152.05q0,65.25,0,130.49a2.52,2.52,0,0,1-1.37,2.48Q229.8,329.18,158.1,373.4a2,2,0,0,1-2.41,0Q93.59,335,31.47,296.8c-1.48-.92-1.48-.91,0-1.84q33.84-21.27,67.66-42.55a2.06,2.06,0,0,1,2.51,0q26.85,15.22,53.72,30.39a2.58,2.58,0,0,0,3,.11q36.72-20.82,73.5-41.53a2.14,2.14,0,0,0,1.31-2.17q-.06-43.14,0-86.28c0-1.1-.06-1.44-1.23-.78Q195,173,158.15,193.79a2,2,0,0,1-2.32.1Q119,173,82.09,152.21c-1.36-.77-1.35-.77-1.35.8q0,43,0,86c0,.9-.1,1.71,1,2.22,1,.45,0,.79-.34,1-2.46,1.59-5,3.15-7.43,4.71L14.24,284.46c-.64.4-1.18,1-2.16.44a2.22,2.22,0,0,1-1.14-2.19Q11,152,11,21.31c0-.46-.22-1.07.18-1.34s.95.23,1.37.49q71.55,44.07,143.07,88.17a2.18,2.18,0,0,0,2.67,0q68.25-42.1,136.54-84.15c2.3-1.42,4.61-2.81,6.88-4.26.86-.54,1.3-.53,1.22.64,0,.51,0,1,0,1.56Z"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HomeHotProductGSAP from "@/common/GSAP/Home/home_product"
export default {
  name: "HomeHotProduct",
  mixins: [HomeHotProductGSAP],
  props: {
    page_data: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      image_loaded: 0
    }
  },
  watch: {
    page_data() {
      if (this.page_data.length > 0) {
        this.SetProductData(0, this.page_data.length)
      }
    }
  },
  computed: {
    active_data() {
      return this.page_data.filter(item => item.status == 'Y')
    }
  },
  mounted() {
    this.InitGSAP()
    // console.log(this.page_data)
    if (this.page_data.length > 0) {
      this.SetProductData(0, this.page_data.length)
    }
  },
  methods: {
    onImgLoad() {
      // console.log(`products ${index} is loaded`)
      this.image_loaded += 1
      if (this.image_loaded == this.page_data.length) {
        this.$emit("image-loaded")
      }
    }
  }
}
</script>