<template>
  <section id="AboutQuality">
    <div class="row">
      <header class="title_box">
        <h2 class="title title_mark"><b>品質管理</b></h2>
        <p class="sub_title"><b>QUALITY</b></p>
      </header>

      <div class="content_box">
        <ol>
          <li
            v-for="(item, item_index) in quality_content"
            :key="`quality_${item.column_id}`"
          >
            <div class="header">
              <p>SERVICE</p>
              <span>{{ item_index + 1 }}</span>
            </div>
            <div class="content">
              <h4>{{ item.title }}</h4>
              <div v-html="item.content"></div>
            </div>
          </li>
        </ol>
      </div>

      <!-- <div class="bg_text_box">
        <span>QUALITY</span>
      </div> -->
    </div>
  </section>
</template>

<script>
import AboutStoryGSAP from "@/common/GSAP/About/about_quality.js"
export default {
  name: "AboutQuality",
  mixins: [AboutStoryGSAP],
  props: {
    quality_content: {
      require: true
    }
  },
  watch: {
    quality_content() {
      if (this.quality_content.length > 0) {
        this.$nextTick(() => { this.InitGSAP() })
      }
    }
  }
}
</script>