export default {
    methods: {
        SendPostData(url, data) {
            this.$store.commit("SetPageLoading", 1)
            return new Promise(resolve => {
                this.axios.post(url, data)
                    .then((response) => {
                        this.$store.commit("SetPageLoading", -1)
                        if (response.data.status == 'error') {
                            console.log(response.data.msg)
                            resolve("error");
                        } else {
                            resolve(response.data);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        resolve("error");
                    });
            })
        },
        SendGetData(url) {
            this.$store.commit("SetPageLoading", 1)
            return new Promise(resolve => {
                this.axios.get(url)
                    .then((response) => {
                        this.$store.commit("SetPageLoading", -1)
                        if (response.data.status == "error") {
                            console.log(response.data.msg);
                            resolve("error");
                        } else {
                            resolve(response.data);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        resolve("error");
                    });
            })
        },
        SendPostDataGetAll(url, data) {
            this.$store.commit("SetPageLoading", 1)
            return new Promise(resolve => {
                this.axios.post(url, data)
                    .then((response) => {
                        this.$store.commit("SetPageLoading", -1)
                        if (response.data.status == 'error') {
                            console.log(response.data.msg)
                            resolve("error");
                        } else {
                            resolve(response);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        resolve("error");
                    });
            })
        },
    }
};