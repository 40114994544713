<template>
  <header id="MainHeader">
    <div class="header">
      <router-link to="/" class="logo link_mark">
        <img src="/img/nav_logo_1.svg" />
      </router-link>

      <a
        :class="{ active: menu }"
        class="menu_btn link_mark"
        @click="ToggleMenu"
      >
        <i></i>
        <i></i>
        <i></i>
      </a>
    </div>
  </header>
</template>


<script>
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import MainMenuGSAP from "@/common/GSAP/main_menu.js"
export default {
  name: "MainHeader",
  mixins: [SmoothScrollResister, MainMenuGSAP],
  data() {
    return {
      menu: false,
      links: [
        {
          name: "關於我們",
          sub_title: "ABOUT"
        },
        {
          name: "全台經銷",
          sub_title: "DEALERS"
        },
        {
          name: "產品列表",
          sub_title: "PRODUCTS"
        },
        {
          name: "客製化選色",
          sub_title: "CUSTOMIZE"
        },
        {
          name: "最新消息",
          sub_title: "NEWS"
        },
        {
          name: "聯絡我們",
          sub_title: "CONTACT"
        },
        {
          name: "影音相簿",
          sub_title: "VIDEO GALLERY"
        },
        {
          name: "檔案下載",
          sub_title: "DOWNLOAD CENTER"
        }
      ]
    }
  },
  methods: {

    ToggleMenu() {
      if (this.menu) {
        this.ModalToggle(false)
        this.CloseMenu()
      }
      else {
        this.ModalToggle(true)
        this.OpenMenu()
      }
      this.menu = !this.menu
    },
    SetScrollbar() {
      this.RegisterGsap()
      this.InitGsapMarker()
    }
  }
}
</script>