<template>
  <footer id="MainFooter">
    <div class="row">
      <div class="info_box">
        <address>
          <span class="material-icons"> place </span>{{ company_info.address }}
        </address>
        <p>
          <span class="material-icons"> call </span>{{ company_info.phone }}
        </p>
        <p><span class="material-icons"> fax </span>{{ company_info.fax }}</p>
        <time
          ><span class="material-icons"> watch_later </span>
          {{ company_info.time }}</time
        >
        <p>
          <span class="material-icons"> email </span> {{ company_info.email }}
        </p>
      </div>
      <div class="social_box">
        <p>社群網站</p>
        <ol>
          <li>
            <a
              href="https://www.facebook.com/MJP.Factory"
              target="_blank"
              class="link_mark"
            >
              <svg class="" viewBox="0 0 24 24">
                <title>facebook</title>
                <path
                  d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.874V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12z"
                  fill="#FFF"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCCA2XWZZ7gjEVFHaATvX_5w"
              target="_blank"
              class="link_mark"
            >
              <svg class="" viewBox="0 0 24 24">
                <title>youtube</title>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm5.47-16.231c.602.148 1.077.583 1.237 1.136C19 9.908 19 12 19 12s0 2.092-.293 3.095c-.16.553-.635.988-1.238 1.136C16.38 16.5 12 16.5 12 16.5s-4.378 0-5.47-.268c-.602-.149-1.077-.584-1.237-1.137C5 14.092 5 12 5 12s0-2.092.293-3.095c.16-.553.635-.988 1.237-1.136C7.622 7.5 12 7.5 12 7.5s4.378 0 5.47.269zM14.226 12l-3.659-1.9v3.8l3.66-1.9z"
                  fill="#FFF"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/mjp_factory/"
              target="_blank"
              class="link_mark"
            >
              <svg class="" viewBox="0 0 24 24">
                <title>instagram</title>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm2.829-17.698c-.739-.034-.96-.04-2.829-.04-1.87 0-2.09.006-2.829.04-.682.031-1.053.145-1.3.241-.326.127-.56.278-.805.523a2.171 2.171 0 00-.523.805c-.096.247-.21.618-.24 1.3-.035.739-.042.96-.042 2.829 0 1.87.007 2.09.041 2.829.031.682.145 1.053.241 1.3.127.326.278.56.523.804.246.245.479.397.805.524.247.096.618.21 1.3.24.738.035.96.042 2.829.042 1.87 0 2.09-.008 2.829-.041.682-.031 1.053-.145 1.3-.241.326-.127.56-.278.804-.524.245-.245.397-.478.524-.805.096-.246.21-.617.24-1.3.035-.738.042-.959.042-2.828 0-1.87-.008-2.09-.041-2.829-.031-.682-.145-1.053-.241-1.3a2.164 2.164 0 00-.524-.805 2.172 2.172 0 00-.805-.523c-.246-.096-.617-.21-1.3-.24zm-5.715-1.26C9.86 5.008 10.099 5 12 5c1.901 0 2.14.008 2.886.042.745.034 1.254.153 1.7.325.46.18.85.419 1.24.808.389.389.628.78.807 1.24.173.445.291.954.325 1.699.035.746.043.985.043 2.886 0 1.901-.009 2.14-.043 2.886-.034.745-.152 1.254-.325 1.7a3.43 3.43 0 01-.807 1.24c-.39.389-.78.628-1.24.807-.445.173-.955.291-1.7.325-.746.034-.985.042-2.886.042-1.9 0-2.14-.008-2.886-.042-.745-.034-1.254-.152-1.7-.325a3.43 3.43 0 01-1.239-.808 3.428 3.428 0 01-.807-1.24c-.173-.445-.292-.954-.326-1.699C5.008 14.14 5 13.901 5 12c0-1.901.008-2.14.042-2.886.034-.745.153-1.254.326-1.7.178-.46.418-.85.807-1.239.389-.39.78-.628 1.24-.807.445-.173.954-.292 1.699-.326zM12 8.405a3.594 3.594 0 100 7.19 3.594 3.594 0 000-7.19zm0 5.928a2.333 2.333 0 110-4.666 2.333 2.333 0 010 4.666zm4.577-6.07a.84.84 0 11-1.68 0 .84.84 0 011.68 0z"
                  fill="#FFF"
                ></path>
              </svg>
            </a>
          </li>
        </ol>
      </div>
      <div class="right_side_box">
        <img class="logo" src="/img/nav_logo_1.svg" />
      </div>
    </div>
    <div class="design_box">
      <div class="row">
        <p>
          Copyright ©2021 MJP Inc. 著作權所有，並保留一切權利。<span>
            網站設計：<a href="https://www.yongxin-design.com/" target="_blank"
              >泳欣事業有限公司</a
            >
          </span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import AxiosPlugin from "@/common/common_methods.js"
import { getCompanyInfo } from "@/api/footer.js"
export default {
  name: "MainFooter",
  mixins: [AxiosPlugin],
  data() {
    return {
      company_info: {
        address: "",
        phone: "",
        fax: "",
        email: "",
        time: ""
      }
    }
  },
  methods: {
    async GetInfoData() {
      getCompanyInfo().then((res) => {
        this.company_info = {
          address: res.data.filter(item => item.column_name == "company_address")[0].title,
          fax: res.data.filter(item => item.column_name == "company_fax")[0].title,
          phone: res.data.filter(item => item.column_name == "company_phone")[0].title,
          email: res.data.filter(item => item.column_name == "company_email")[0].title,
          time: res.data.filter(item => item.column_name == "company_time")[0].title
        }
      })
    },
  },
  created() {
    this.GetInfoData()
  }
}
</script>