<template>
  <header id="AboutCover">
    <div class="row">
      <p class="title_mark">ABOUT MJP</p>
      <h2 class="title_mark">關於我們</h2>
    </div>
    <div class="bg_img_box">
      <div class="img_box" v-for="item in images" :key="item.carousel_id">
        <img class="lg" :src="item.pc_image" />
        <img class="sm" :src="item.mb_image" />
      </div>
    </div>
  </header>
</template>

<script>
import about_cover_gsap from "@/common/GSAP/About/about_cover.js"
export default {
  name: "AboutCover",
  mixins: [about_cover_gsap],
  props: {
    images: {
      require: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
  watch: {
    images() {
      if (this.images.length > 0) {
        this.$nextTick(() => {
          document.querySelectorAll("#AboutCover .bg_img_box .img_box")[0].classList.add("active")
          this.PlayAnimate()
          this.RegisterGsap("About")
          this.InitGsapMarker()
        })
      }
    }
  },
  created() {
  }
}
</script>