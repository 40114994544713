<template>
  <main id="Customize">
    <PrintOrder
      ref="PrintOrder"
      :user_data="user_data"
      :user_info="user_info"
      :product_data="product_data"
    />
    <div class="main_container">
      <FormModal
        ref="FormModal"
        :product_data="product_data"
        v-on:print="Print"
        v-model="user_info"
      />
      <div class="left_box">
        <div class="img_box">
          <template v-if="product_data != null">
            <template v-for="item in product_data.option_type">
              <img
                v-if="item.image"
                :key="item.id"
                :style="`z-index:${parseInt(item['z-index']) + 2}`"
                :src="
                  `/img/customize/${$route.params.id}/${angle}/${
                    item.name
                  }/${GetUserValue(item.name)}.png?time=${new Date().getTime()}`
                "
                :class="image_status"
              />
            </template>
            <img
              :style="`z-index:${product_data.option_type.length + 1}`"
              :src="
                `/img/customize/${
                  $route.params.id
                }/${angle}/base.png?time=${new Date().getTime()}`
              "
              :class="image_status"
            />
            <img
              style="z-index: 1"
              :src="
                `/img/customize/${
                  $route.params.id
                }/${angle}/shadow.png?time=${new Date().getTime()}`
              "
              :class="image_status"
            />
          </template>
        </div>

        <div class="bullet_box">
          <ol>
            <li @click="ChangeAngel(1)" :class="{ active: angle == 1 }"></li>
            <li @click="ChangeAngel(2)" :class="{ active: angle == 2 }"></li>
          </ol>
        </div>
      </div>
      <OptionDrawerPc
        v-if="product_data != null"
        :option_list="product_data.option_type"
        :name="product_data.name"
        :user_data="user_data"
        v-on:print="OpenForm"
        v-on:update-data="UpdateUserData"
      />
      <OptionDrawerMb
        v-if="product_data != null"
        :option_list="product_data.option_type"
        :name="product_data.name"
        :user_data="user_data"
        v-on:print="OpenForm"
        v-on:update-data="UpdateUserData"
      />
    </div>
    <MainFooter />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import MainFooter from '@/components/MainFooter';
import OptionDrawerPc from '@/components/Customize/OptionDrawerPc';
import OptionDrawerMb from '@/components/Customize/OptionDrawerMb';
import PrintOrder from '@/components/Customize/PrintOrder';
import FormModal from '@/components/Customize/FormModal';
import { GetMetaData } from '@/common/meta.js';
import { ReturnErrorPage } from '@/common/prerender_event.js';
export default {
  name: 'Customize',
  mixins: [SmoothScrollResister],
  components: {
    MainFooter,
    OptionDrawerPc,
    OptionDrawerMb,
    PrintOrder,
    FormModal,
  },
  data() {
    return {
      product_data: null,
      user_data: null,
      user_info: null,
      angle: 1,
      active_option: 1,
      image_status: '',
      status_timer: null,
      image_load: 0,
      meta_product_title: '',
      meta_data: null,
    };
  },
  computed: {
    active_list() {
      let option_list = [];
      if (this.product_data.option_list[this.active_option * 2 - 2]) {
        option_list.push(
          this.product_data.option_list[this.active_option * 2 - 2]
        );
      }
      if (this.product_data.option_list[this.active_option * 2 - 1]) {
        option_list.push(
          this.product_data.option_list[this.active_option * 2 - 1]
        );
      }
      return option_list;
    },
  },
  methods: {
    loadData() {
      let base_url = '/';
      window.location.hostname == 'localhost'
        ? (base_url = '/')
        : (base_url = '/');
      fetch(
        `${base_url}config/${
          this.$route.params.id
        }.json?ver=${new Date().getTime()}`
      )
        .then((r) => r.json())
        .then(
          (json) => {
            this.product_data = json;
            console.log(this.product_data);
            this.meta_product_title = this.product_data.name;
            this.meta_data = GetMetaData(
              this.meta_product_title,
              '歡迎使用MJP 茂建科技懸吊客製化系統，您可以依照個人喜好進行懸吊外觀客製化。',
              ''
            );
            this.SetUserData();
            this.$nextTick(() => {
              window.prerenderReady = true;
            });
          },
          (response) => {
            console.log('Error loading json:', response);
            ReturnErrorPage();
          }
        );
    },
    SetUserData() {
      this.user_data = [];
      this.user_info = [];
      this.product_data.option_type.forEach((item) => {
        let data = {
          name: item.name,
          value: item.default_value,
        };
        item.require ? (data.require = item.require) : '';
        this.user_data.push(data);
      });
      this.product_data.form_data.forEach((item) => {
        let data = {
          name: item.name,
          require: item.require,
          value: '',
        };
        this.user_info.push(data);
      });
    },
    GetUserValue(key) {
      return this.user_data.filter((item) => item.name == key)[0].value;
    },
    UpdateUserData({ key, value }) {
      this.user_data.forEach((item) => {
        if (item.name == key) {
          item.value = value;
        }
      });
    },
    ChangeAngel(val) {
      this.image_status = 'out';
      if (this.status_timer != null) {
        clearTimeout(this.status_timer);
      }
      this.status_timer = setTimeout(() => {
        this.angle = val;
        this.image_status = 'in';
      }, 1000);
    },
    Print() {
      this.GA_CreateOrder(this.product_data);
      this.$refs.PrintOrder.Print();
    },
    OpenForm() {
      this.$refs.FormModal.Open();
    },
  },
  mounted() {
    this.RegisterGsap('Customize');
    this.$emit('init-cursor');
    this.SetFixEl(document.querySelector('#FormModal'));
    setTimeout(() => {
      this.image_status = 'in';
    }, 1000);
  },
  created() {
    this.loadData();
  },
  watch: {
    image_load() {
      let images = document.querySelectorAll('.img_box img');
      this.image_load == images.length ? (this.image_status = 'in') : '';
    },
  },

  metaInfo() {
    return this.meta_data;
  },
};
</script>
