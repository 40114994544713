<template>
  <section id="ProductInfo">
    <div class="row">
      <header>
        <h4>產品介紹</h4>
      </header>
      <div class="content_box" v-html="product_data.description"></div>
      <div class="gallery">
        <ol>
          <li v-for="(item, item_index) in product_data.images" :key="item.id">
            <img
              @click="OpenImage(item_index)"
              @mouseenter="HoverImage(item_index)"
              @mouseleave="HoverImage(-1)"
              :src="item.url"
            />
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductInfo",
  props: {
    product_data: {
      require: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    HoverImage(key) {
      let images = document.querySelectorAll("#ProductInfo .gallery li")
      if (key == -1) {
        images.forEach(item => {
          item.classList.remove("active")
          item.classList.remove("dark")
        })
      }
      else {
        images.forEach((item, item_index) => {
          if (item_index == key) {
            item.classList.remove("dark")
            item.classList.add("active")
          } else {
            item.classList.remove("active")
            item.classList.add("dark")
          }
        })
      }
    },
    OpenImage(index) {
      let images = []
      this.product_data.images.forEach(item => {
        images.push(item.url)
      })
      this.$store.commit("SetImageModal", {
        status: true,
        images: images,
        active_index: index
      })
    }
  },
  mounted() { }
}
</script>