import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    methods: {
        OpenMenu() {
            let menu = gsap.timeline()
            gsap.set("#MenuCover", {
                width: "100vw",
                height: "100vh"
            })
            menu.fromTo("#MenuCover", {
                opacity: 0
            }, {
                opacity: 1,
                duration: .3,
                ease: "none"
            }, 0)
            menu.fromTo("main", {
                filter: "blur(0px)"
            }, {
                filter: "blur(20px)",
                duration: .3,
                ease: "none"
            }, 0)
            menu.fromTo("#MainFooter", {
                filter: "blur(0px)"
            }, {
                filter: "blur(20px)",
                duration: .3,
                ease: "none"
            }, 0)
        },
        CloseMenu() {
            let menu = gsap.timeline()

            menu.fromTo("main", {
                filter: "blur(20px)"
            }, {
                filter: "blur(0px)",
                duration: .3,
                ease: "none"
            }, 0)

            menu.fromTo("#MainFooter", {
                filter: "blur(20px)"
            }, {
                filter: "blur(0px)",
                duration: .3,
                ease: "none"
            }, 0)
            menu.fromTo("#MenuCover", {
                opacity: 1
            }, {
                opacity: 0,
                duration: .3,
                ease: "none"
            }, 0)

            gsap.set("#MenuCover", {
                width: "0vw",
                height: "0vh"
            })
        },
    },
    mounted() {
        // this.RegisterGsap()
        //this.InitGsapMarker()
    }
}