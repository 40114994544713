import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)


let router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [{
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: '/dealer',
      name: 'Dealer',
      component: () => import( /* webpackChunkName: "dealer" */ '../views/Dealer.vue')
    },
    {
      path: '/news/list/:type/',
      name: 'NewsList',
      component: () => import( /* webpackChunkName: "news_list" */ '../views/NewsList.vue')
    },
    {
      path: '/news/page/:id',
      name: 'NewsPage',
      component: () => import( /* webpackChunkName: "news_page" */ '../views/NewsPage.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    {
      path: '/video/:type/',
      name: 'VideoList',
      component: () => import( /* webpackChunkName: "vide_list" */ '../views/VideoList.vue')
    },
    {
      path: '/download_center',
      name: 'DownloadCenter',
      component: () => import( /* webpackChunkName: "download_center" */ '../views/DownloadCenter.vue')
    },
    // {
    //   path: '/products/list/:type/:page',
    //   name: 'ProductListOLD',
    //   component: () => import( /* webpackChunkName: "product_list_old" */ '../views/ProductList.vue')
    // },
    {
      path: '/products/list/:type/',
      name: 'ProductList',
      component: () => import( /* webpackChunkName: "product_list" */ '../views/ProductList.vue')
    },
    {
      path: '/product/page/:id',
      name: 'ProductPage',
      component: () => import( /* webpackChunkName: "product_page" */ '../views/ProductPage.vue')
    },
    {
      path: '/customize_list',
      name: 'CustomizeList',
      component: () => import( /* webpackChunkName: "customize_list" */ '../views/CustomizeList.vue')
    },
    {
      path: '/customize/:id',
      name: 'Customize',
      component: () => import( /* webpackChunkName: "customize" */ '../views/Customize.vue')
    },
    {
      path: '/index.html',
      redirect: '/',
    },
    {
      path: '/home',
      redirect: '/',
    },
    {
      path: '/index.php',
      name: 'BotHome',
      component: Home
    },
    {
      path: '/error_page',
      name: 'ErrorPage',
      component: () => import( /* webpackChunkName: "ErrorPage" */ '../views/ErrorPage.vue')
    },
    {
      path: '*',
      redirect: "/error_page"
    },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
export default router;