<template>
  <main id="Home">
    <div class="container">
      <template v-if="load">
        <Parallax
          v-on:image-loaded="SetImageLoadStatus(0)"
          :page_data="page_data.carousel"
        />
        <About :page_data="page_data.about" />
        <div class="about_bottom"></div>
        <News
          v-if="page_data.news != null && page_data.news.length > 0"
          v-on:image-loaded="SetImageLoadStatus(1)"
          :page_data="page_data.news"
        />
        <ProductFeature
          v-on:image-loaded="SetImageLoadStatus(2)"
          :page_data="page_data.product_feature"
        />
        <Factory
          :page_data="page_data.factory"
          v-on:image-loaded="SetImageLoadStatus(3)"
        />
        <HotProduct
          v-on:image-loaded="SetImageLoadStatus(4)"
          :page_data="page_data.hot_products"
        />
        <Customize
          v-on:image-loaded="SetImageLoadStatus(5)"
          :page_data="page_data.customize"
        />
        <Video
          :image_load_status="image_load_status"
          :page_data="page_data.video"
        />
        <DealerSupport :page_data="page_data.bottom_link" />
        <MainFooter />
      </template>
    </div>
  </main>
</template>

<script>
import Parallax from "@/components/Home/Parallax.vue";
import About from "@/components/Home/About.vue";
import ProductFeature from "@/components/Home/ProductFeature.vue";
import HotProduct from "@/components/Home/HotProduct.vue";
import News from "@/components/Home/News.vue";
import Video from "@/components/Home/Video.vue";
import Customize from "@/components/Home/Customize.vue";
import DealerSupport from "@/components/Home/DealerSupport.vue";
import Factory from "@/components/Home/Factory.vue";
import MainFooter from "@/components/MainFooter";
import SmoothScrollResister from "@/common/SmoothScrollResister.js";
import { getPage } from "@/api/home.js";
import ScrollTrigger from "gsap/ScrollTrigger";
import { GetMetaData } from "@/common/meta";

export default {
  name: "Home",
  components: {
    Parallax,
    About,
    ProductFeature,
    HotProduct,
    News,
    Video,
    Customize,
    DealerSupport,
    MainFooter,
    Factory,
  },
  mixins: [SmoothScrollResister],
  data() {
    return {
      load: false,
      image_load_status: [
        false, //carousel
        false, //news
        false, //feature
        false, //factory
        false, //hot products
        false, //customize
      ],
      page_data: {
        carousel: [],
        about: {},
        product_feature: {},
        hot_products: [],
        customize: {},
        bottom_link: [],
        news: [],
        factory: {},
      },
      meta_data: null,
    };
  },
  methods: {
    async GetData() {
      this.$store.commit("SetPageLoading", 1);
      await getPage().then((res) => {
        res.forEach((data, index) => {
          let key = Object.keys(this.page_data)[index];
          this.page_data[key] = data.data;
        });
      });
      this.page_data.news.sort((a, b) => {
        return parseInt(a.position) - parseInt(b.position);
      });
      if (this.page_data.news == null || this.page_data.news.length <= 0) {
        this.image_load_status[1] = true;
      }
      if (
        this.page_data.hot_products == null ||
        this.page_data.hot_products.length <= 0
      ) {
        this.image_load_status[4] = true;
      }
      this.load = true;
      this.$store.commit("SetPageLoading", -1);
      this.$nextTick(() => {
        window.prerenderReady = true;
      });
    },
    SetImageLoadStatus(index) {
      this.$set(this.image_load_status, index, true);
    },
  },
  watch: {
    image_load_status: {
      handler() {
        let loaded = true;
        this.image_load_status.forEach((item) => {
          if (item != true) {
            loaded = false;
          }
        });
        loaded
          ? (this.$store.commit("SetPageLoading", -1),
            setTimeout(() => {
              ScrollTrigger.refresh();
            }, 100))
          : "";
      },
      deep: true,
      immediate: true,
    },
    $route() {
      this.GetData();
      this.RegisterGsap("Home");
      this.$emit("init-cursor");
    },
  },
  created() {
    this.GetData();
    this.meta_data = GetMetaData("首頁", "", "");
  },
  mounted() {
    this.load = false;
    this.image_load_status = [
      false, //carousel
      false, //news
      false, //feature
      false, //factory
      false, //hot products
      false, //customize
    ];
    this.RegisterGsap("Home");
    this.$emit("init-cursor");
    // this.$store.commit('SetPageLoading', 1);
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
