import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            about_story_gsap: null
        }
    },
    methods: {
        InitGSAP() {
            this.RegisterGsap("About")
            let vm = this
            ScrollTrigger.matchMedia({
                "(min-width:769px)"() {
                    vm.about_story_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutStory",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                            // markers: true
                        }
                    })
                    vm.about_story_gsap.fromTo("#AboutStory header h2 ", {
                        webkitFilter: "blur(40px)",
                        y: "100px",
                        opacity: 0,
                    }, {
                        webkitFilter: "blur(0px)",
                        y: 0,
                        opacity: 1,
                        duration: 1,
                        delay: 1,
                        ease: "power2.out",
                    }, 0)
                    vm.about_story_gsap.fromTo("#AboutStory .content", {
                        y: "100px",
                        opacity: 0
                    }, {
                        y: "0px",
                        opacity: 1,
                        delay: 1.5,
                        duration: .5
                    }, 0)
                    vm.about_story_gsap.fromTo("#AboutStory .story_img", {
                        webkitFilter: "blur(40px)",
                        opacity: 0,
                    }, {
                        webkitFilter: "blur(0px)",
                        opacity: 1,
                        duration: 1,
                        delay: 2
                    }, 0)
                },
                "(max-width:768px)"() {
                    vm.about_story_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutStory",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                            // markers: true
                        }
                    })
                    vm.about_story_gsap.fromTo("#AboutStory header h2 ", {
                        webkitFilter: "blur(40px)",
                        y: "100px",
                        opacity: 0,
                    }, {
                        webkitFilter: "blur(0px)",
                        y: 0,
                        opacity: 1,
                        duration: 1,
                        ease: "power2.out",
                    }, 0)
                    vm.about_story_gsap.fromTo("#AboutStory .content", {
                        y: "100px",
                        opacity: 0
                    }, {
                        y: "0px",
                        opacity: 1,
                        delay: .5,
                        duration: .5
                    }, 0)
                    vm.about_story_gsap.fromTo("#AboutStory .story_img", {
                        webkitFilter: "blur(40px)",
                        opacity: 0,
                    }, {
                        webkitFilter: "blur(0px)",
                        opacity: 1,
                        duration: 1,
                        delay: 1
                    }, 0)
                }
            })
            this.InitGsapMarker()
        }
    },
    mounted() {

    },
    beforeDestroy() {
        this.about_story_gsap != null ? this.about_story_gsap.kill() : ""
    }
}