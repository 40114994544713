import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"
gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});

export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            product_list_gsap: null,
        }
    },
    methods: {
        InitCard(start_index) {
            let cards = document.querySelectorAll("#ProductList .card_list .card")
            cards.forEach((card, card_index) => {
                if (card_index > start_index) {
                    let card_animation = gsap.timeline({
                        scrollTrigger: {
                            trigger: card,
                            start: "top 80%",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                        }
                    })
                    card_animation.fromTo(
                        card, {
                            opacity: 0,
                            webkitFilter: "blur(40px)",
                        }, {
                            opacity: 1,
                            webkitFilter: "blur(0px)",
                            ease: "none",
                            duration: .6
                        }
                    )
                }
            })
        }
    },
    mounted() {
        // this.RegisterGsap("ProductList")
        // this.InitCard(-1)
        // this.InitGsapMarker()
    },
    beforeDestroy() {
        this.product_list_gsap != null ? this.product_list_gsap.kill() : ""
    }
}